import { Component, OnInit, ViewChild, AfterViewInit, Renderer2 } from '@angular/core';
import {DayPilot, DayPilotSchedulerComponent} from 'daypilot-pro-angular';
import { PerfectScrollbarConfigInterface, PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';

@Component({
  selector: 'app-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.css']
})
export class SchedulerComponent implements OnInit {

  @ViewChild('scheduler')
  scheduler!: DayPilotSchedulerComponent;

  public configPS: PerfectScrollbarConfigInterface = { wheelPropagation: true };
  @ViewChild(PerfectScrollbarComponent)
  componentRef?: PerfectScrollbarComponent;
  directiveRef?: PerfectScrollbarDirective;
  //@ViewChild(PerfectScrollbarDirective, { static: true })
  
  
  events: any[] = [];

  config: DayPilot.SchedulerConfig = {
    scale: "Day",
    startDate: DayPilot.Date.today().firstDayOfMonth(),
    days: DayPilot.Date.today().daysInMonth(),
    timeHeaders: [
      {groupBy: "Month"},
      {groupBy: "Day", format: "d"}
    ],
    cellWidthSpec: "Auto",
    
    eventHeight: 30,
    treeEnabled: true,
    onBeforeEventRender: args => {
      if (args.data.text && args.data.text === "Vacation") {
        args.data.barColor = "#0F9D58";
        args.data.barBackColor = "#0F9D58";
      }
    },
    resources: [
      { name: 'Kino 1', id: 'K1' },
      { name: 'Kino 2', id: 'K2' },
    ],
    
    onEventMove: args => {
      let data = {
        id: args.e.id(),
        newStart: args.newStart.toString(),
        newEnd: args.newEnd.toString(),
        newResource: args.newResource
      };

      //this.ds.moveEvent(data).subscribe(result => {
      //  this.scheduler.control.message("Updated");
      //});
    },
    onEventResize: args => {
      let data = {
        id: args.e.id(),
        newStart: args.newStart.toString(),
        newEnd: args.newEnd.toString(),
        newResource: args.e.resource()  // existing resource id
      };

      //this.ds.moveEvent(data).subscribe(result => {
      //  this.scheduler.control.message("Updated");
      //});
    },
    onTimeRangeSelect: args => {

      DayPilot.Modal.prompt("Event name: ", "New event").then(modal => {
        if (modal.canceled) {
          return;
        }
        this.scheduler.control.clearSelection();
        const e: any = {
          id: null,
          start: args.start.toString(),
          end: args.end.toString(),
          text: modal.result,
          resource: args.resource
        };

        
      });
    }
  };

  
    /**
 * Constructor
 * @param Renderer2                 renderer
 */
     constructor(
      private renderer: Renderer2
    ) {}

 
  ngOnInit(): void {
  }

   /**
   * Open Media-body
   *
   * @param event   Mail Read
   */

  
  
    showComposeSidebar(event) {
      const toggleIcon = document.getElementById('compose-sidebar');
      const toggleSidebar = document.getElementById('sidebar-left');
      const toggleOverlay = document.getElementById('app-content-overlay');
      
      if (event.currentTarget.className === 'btn btn-danger btn-glow btn-block my-2 compose-btn') {
        this.renderer.addClass(toggleIcon, 'show');
       this.renderer.removeClass(toggleSidebar, 'show');
        this.renderer.addClass(toggleOverlay, 'show');
      } else if (event.currentTarget.className === 'btn btn-danger btn-glow btn-block my-2 compose-btn show') {
        this.renderer.removeClass(toggleIcon, 'show');
       }
    }
  
    showCompose(event) {
      const toggleIcon = document.getElementById('compose-sidebar');
      const toggleOverlay = document.getElementById('app-content-overlay');
      if (event.currentTarget.className === 'close close-icon' || 'app-content-overlay') {
        this.renderer.removeClass(toggleIcon, 'show');
        this.renderer.removeClass(toggleOverlay, 'show');
      }
    }

  

}
