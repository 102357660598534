<div class="rental-manager-application">
    <div class="app-content content">
    
    
        <div class="content-right">
    
            <div class="content-overlay"></div>
            <div class="content-wrapper">
              <div class="content-body">
                <!-- email app overlay -->
                <div class="email-app-area">
                  <div class="email-app-list-wrapper">
                    <div class="email-app-list">
    
                      <!-- H E A D E R   S T A R T-->
                      <div class="email-action">
                        <!-- action left start here -->
                        <div class="action-left d-flex align-items-center">
        
                          
                          <!-- delete unread dropdown -->
                          <ul class="list-inline m-0 d-flex">
                            <li class="list-inline-item mail-delete" (click)="showComposeSidebar($event)">
                              <button type="button" class="btn btn-icon action-icon btn-success">
                                TAG
                              </button>
                            </li>

                            <li class="list-inline-item mail-delete" (click)="showComposeSidebar($event)">
                              <button type="button" class="btn btn-icon action-icon btn-success">
                                WOCHE
                              </button>
                            </li>

                            <li class="list-inline-item mail-delete" (click)="showComposeSidebar($event)">
                              <button type="button" class="btn btn-icon action-icon btn-success">
                                MONAT
                              </button>
                            </li>
                          </ul>
                        </div>
                        <!-- action left end here -->
        
                        <!-- action right start here -->
                        <div class="action-right d-flex flex-grow-1 align-items-left justify-end">
                          <!-- search bar  -->
                         
                         
                         
                          <button class="btn btn-icon email-pagination-prev d-none d-sm-block">
                            <i class="ficon feather ft-chevron-left"></i>
                          </button>
                          <button class="btn btn-icon email-pagination-next d-none d-sm-block">
                            <i class="ficon feather ft-chevron-right"></i>
                          </button>
                        </div>
                      </div>
                      <!-- H E A D E R   E N D-->
        
                      <!-- L I S T   S T A R T-->
                      <div class="email-user-list list-group"  [perfectScrollbar]="configPS">
                        <daypilot-scheduler [config]="config" [events]="events" #scheduler></daypilot-scheduler>
                        <!-- email user list end -->
        
                        
                      </div>
                      <!-- L I S T   E N D-->
                    </div>
                  </div>
                  <!--/ Email list Area -->
        

                </div>
              </div>
            </div>
          </div>
    </div>  
    </div>      

