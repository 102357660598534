// Default menu settings configurations

export interface MenuItem {
  title: string;
  icon: string;
  page: string;
  isExternalLink?: boolean;
  issupportExternalLink?: boolean;
  isStarterkitExternalLink?: boolean;
  badge: { type: string, value: string };
  submenu: {
    items: Partial<MenuItem>[];
  };
  section: string;
}

export interface MenuConfig {
  horizontal_menu: {
    items: Partial<MenuItem>[]
  };
  vertical_menu: {
    items: Partial<MenuItem>[]
  };
}

export const MenuSettingsConfig: MenuConfig = {
  horizontal_menu: {  
    items: [
      
      {
        title: 'Dashboard',
        icon: 'la-home',
        page: '/dashboard/hospital',
        badge: { type: 'badge-info', value: '3' },
      },
      {
        title: 'Abrechnung',
        icon: 'la-list',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Übersicht',
              page: '/logs'
            },
          ]
        }
      },
      {
        title: 'Patienten',
        icon: 'la-users',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Übersicht',
              page: 'null'
            },
          ]
        }
      },
      {
        title: 'Resourcen',
        icon: 'la-stack-overflow',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Personal',
              page: 'null',
            },
            {
              title: 'Warenwirtschaft',
              page: 'null'
            },
          ]
        }
      },
      {
        title: 'Personal',
        icon: 'la-user',
        page: '/base',
        submenu: {
          items: [
            {
              title: 'Übersicht',
              page: 'null'
            },
          ]
        }
      },
     
      {
        title: 'Stammdaten',
        icon: 'la-list',
        page: '/base',
        submenu: {
          items: [
            {
              title: 'Benutzer',
              page: '/base/user-manager'
            },
          ]
        }
      },
      {
        title: 'Admin',
        icon: 'la-cog',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'System',
              page: 'null'
            },
            {
              title: 'Einstellungen',
              page: 'null'
            },
            {
              title: 'Backup',
              page: 'null'
            },
          ]
        }
      },
 
    ]
  },

  vertical_menu: {  
    items: [
      
      {
        title: 'Dashboard',
        icon: 'la-home',
        page: '/dashboard/hospital',
        badge: { type: 'badge-info', value: '3' },
      },
      {
        title: 'Abrechnung',
        icon: 'la-list',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Übersicht',
              page: '/logs'
            },
          ]
        }
      },
      {
        title: 'Patienten',
        icon: 'la-users',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Übersicht',
              page: 'null'
            },
          ]
        }
      },
      {
        title: 'Resourcen',
        icon: 'la-stack-overflow',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Personal',
              page: 'null',
            },
            {
              title: 'Warenwirtschaft',
              page: 'null'
            },
          ]
        }
      },
      {
        title: 'Personal',
        icon: 'la-user',
        page: '/base',
        submenu: {
          items: [
            {
              title: 'Übersicht',
              page: 'null'
            },
          ]
        }
      },
     
      {
        title: 'Stammdaten',
        icon: 'la-list',
        page: '/base',
        submenu: {
          items: [
            {
              title: 'Benutzer',
              page: '/base/user-manager'
            },
          ]
        }
      },
      {
        title: 'Admin',
        icon: 'la-cog',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'System',
              page: 'null'
            },
            {
              title: 'Einstellungen',
              page: 'null'
            },
            {
              title: 'Backup',
              page: 'null'
            },
          ]
        }
      },
 
    ]
  },

};





